import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
// import Instagram from '../../components/instagram';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import Style from '../../styles/recruit/interview.module.scss';
import ImgMitsukawa from '../../images/recruit/mitsukawa.jpg';

class WebEngineer extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
  }

  render() {
    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    return (
      <Layout>
        <Transition ref={this.child}>
          <Header transition={transition} />
          <main className={Style.position}>
            <section className={Style.interview}>
              <div className={Style.inner}>
                <p className={Style.profession}>Join our team <br className="spDisBlock" /><span>Webエンジニア</span></p>
                <h1>「編集」という力で、想像もできない価値を生み出していく。</h1>
                <p className={Style.intro}>
                  コンクリートの土手だって、見方を変えればジャンプ台になる。
                  <br className="pcDisBlock" />
                  編集の力で、世の中の「当たり前」を変え、
                  <br className="pcDisBlock" />
                  まだ想像もできない価値を生み出していく。
                </p>
              </div>
              <figure>
                <img src={ImgMitsukawa} alt="光川貴浩" />
              </figure>
              <div className={`${Style.inner} ${Style.content}`}>
                <h2>「抜き出しなどの見出しをここに入れる。」</h2>
                <p>川辺の防波堤や、土砂崩れを防ぐために設置された土手。この傾斜した坂のことを、スケートボードやBMXなどのストリートカルチャーでは”バンク”と呼び、ジャンプ台になり、ときにグラフィティのキャンバスにもなります。 </p>
                <p>世間一般の人から見ると、単なるコンクリートの塊ですが、ものの見方を変えたり、前提となってる常識を疑えば、これまで誰も想像できなかった価値を生み出したり、あるいは楽しむことさえできたりする。私たちはこうした考え方と編集やデザインという道具をもって、世の中の「当たり前」を変えていく。</p>
                <p> 少し偉そうですが、これがバンクトゥのスタッフがめざす使命であり、仕事の姿勢でもあります。川辺の防波堤や、土砂崩れを防ぐために設置された土手。この傾斜した坂のことを、スケートボードやBMXなどのストリートカルチャーでは”バンク”と呼び、ジャンプ台になり、ときにグラフィティのキャンバスにもなります。</p>
                <p>  世間一般の人から見ると、単なるコンクリートの塊ですが、ものの見方を変えたり、前提となってる常識を疑えば、これまで誰も想像できなかった価値を生み出したり、あるいは楽しむことさえできたりする。私たちはこうした考え方と編集やデザインという道具をもって、世の中の「当たり前」を変えていく。</p>
                <p> 少し偉そうですが、これがバンクトゥのスタッフがめざす使命であり、仕事の姿勢でもあります。</p>
              </div>
              <figure>
                <img src={ImgMitsukawa} alt="光川貴浩" />
              </figure>
              <div className={`${Style.inner} ${Style.content}`}>
                <h2>「抜き出しなどの見出しをここに入れる。」</h2>
                <p>川辺の防波堤や、土砂崩れを防ぐために設置された土手。この傾斜した坂のことを、スケートボードやBMXなどのストリートカルチャーでは”バンク”と呼び、ジャンプ台になり、ときにグラフィティのキャンバスにもなります。 </p>
                <p>世間一般の人から見ると、単なるコンクリートの塊ですが、ものの見方を変えたり、前提となってる常識を疑えば、これまで誰も想像できなかった価値を生み出したり、あるいは楽しむことさえできたりする。私たちはこうした考え方と編集やデザインという道具をもって、世の中の「当たり前」を変えていく。</p>
                <p> 少し偉そうですが、これがバンクトゥのスタッフがめざす使命であり、仕事の姿勢でもあります。川辺の防波堤や、土砂崩れを防ぐために設置された土手。この傾斜した坂のことを、スケートボードやBMXなどのストリートカルチャーでは”バンク”と呼び、ジャンプ台になり、ときにグラフィティのキャンバスにもなります。</p>
                <p>  世間一般の人から見ると、単なるコンクリートの塊ですが、ものの見方を変えたり、前提となってる常識を疑えば、これまで誰も想像できなかった価値を生み出したり、あるいは楽しむことさえできたりする。私たちはこうした考え方と編集やデザインという道具をもって、世の中の「当たり前」を変えていく。</p>
                <p> 少し偉そうですが、これがバンクトゥのスタッフがめざす使命であり、仕事の姿勢でもあります。</p>
              </div>
            </section>
            <section className={`sectionContent ${Style.recruitmentCommunication}`}>
              <h2 className="heading">Recruitment <br />communication</h2>
              <p className="intro">
                碁盤の目の街を縫うように、路地がひしめく京都。
                <br className="pcDisBlock" />
                その数、約13,000本とも。
                <br className="pcDisBlock" />
                そこには観光とは無縁の、京都の素顔が広がっています。
                <br className="pcDisBlock" />
                Googleでさえ、知らない世界へ。
              </p>
              <p className={`btn ${Style.btn}`}>
                <TransitionLink to="/recruit" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                  <span>Contact us</span>
                </TransitionLink>
              </p>
            </section>
            {/*<Instagram />*/}
          </main>
          <Footer transition={transition} />
        </Transition>
      </Layout>
    );
  }
}

export default WebEngineer;
